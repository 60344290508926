import React, {useEffect, useRef, useState} from 'react';
import './TideTooltiper.scss';

const TideTooltiper = () => {

    const containerRef = useRef();
    const [showing, setShowing] = useState(false);
    const [content, setContent] = useState(null);

    useEffect(()=>{
        // --- Follow the mouse ---
        let lastPointedElement = null;
        const mouseMoveHandle = (e)=>{

            //Find which element the mouse is over
            const pointingElement = document.elementFromPoint(e.clientX, e.clientY);
            if( pointingElement !== lastPointedElement ) {
                let tooltipElement = pointingElement;

                while ( !tooltipElement?.dataset?.tooltip && tooltipElement?.parentElement ) {
                    tooltipElement = tooltipElement.parentElement;
                }

                lastPointedElement = pointingElement;

                setShowing(!!tooltipElement?.dataset?.tooltip);
                if (tooltipElement?.dataset?.tooltip) {
                    setContent(tooltipElement.dataset.tooltip);
                }
            }

            //Make the tooltip follow the mouse
            if(!containerRef.current) return;
            const { offsetWidth, offsetHeight } = containerRef.current;
            let y = e.clientY+ 12;
            let x = e.clientX+4;
            const rightMargin = 20;
            const bottomMargin = 5;
            if( window.innerWidth - rightMargin < x + offsetWidth ){
                x = window.innerWidth - offsetWidth - rightMargin;
            }
            if( window.innerHeight - bottomMargin < y + offsetHeight ){
                y = window.innerHeight - offsetHeight - bottomMargin;
            }
            containerRef.current.style.transform = `translate(${x}px, ${y}px)`;
            containerRef.current.style.opacity = 1;

        }
        document.addEventListener( 'mousemove', mouseMoveHandle );

        return ()=>{
            document.removeEventListener( 'mousemove', mouseMoveHandle );
        }
    },[]);

    if(!showing)
        return null;

    return (
        <div className={"TideTooltiper"} ref={containerRef}>
            <div className='content'>{content}</div>
        </div>
    );
};

export default TideTooltiper;