import React from 'react';
import './Button.scss';
import classNames from "classnames";

/**
 *
 * @param children Content of the button
 * @param className Additional classes
 * @param design String (options: "basic", "clear")
 * @param props Additional props for the "button" element
 * @returns {JSX.Element}
 * @constructor
 */
const Button = ({children, className, design="basic", ...props}) => {

    return (
        <button className={classNames("Button", design, className)} {...props}>
            {children}
        </button>
    );
};

export default Button;
