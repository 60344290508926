import React from 'react'
import {ReactComponent as BellIcon} from "../../../../../../../assets/images/icons/bell.svg";
import './NotificationCount.scss';

const NotificationCount = ({notificationsTotal, setShowNotificationsModal}) => {
    return (
        <div className='NotificationCount' onClick={setShowNotificationsModal}>
            <BellIcon />
            {notificationsTotal>0 && <span className='NotificationCount__total'>{notificationsTotal}</span>}
        </div>
    );
}

export default NotificationCount;