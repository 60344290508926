import React from 'react';
import './PasswordInput.scss';
import classNames from "classnames";
import useBoolean from "../../../hooks/useBoolean";
import {ReactComponent as Eye} from "../../../assets/images/icons/eye.svg";
import {ReactComponent as EyeOff} from "../../../assets/images/icons/eye-off.svg";

const PasswordInput = ({ value, onChange, className, label, placeholder, inputProps, ...props }) => {

    const [showingContent,,,toggleShow] = useBoolean();

    return (
        <div className={classNames("PasswordInput", className)} {...props}>

            {label &&
            <label className='text-input-label'>{label}</label>}

            <input className='input-el'
                   placeholder={placeholder}
                   value={value}
                   onChange={onChange}
                   type={showingContent?'text':'password'}
                   {...inputProps}/>

            <button className='show-btn'
                    onClick={toggleShow}
                    type={'button'}
            >
                {showingContent?<Eye />:<EyeOff />}
            </button>
        </div>
    );
};

export default PasswordInput;
