import React from 'react'
import classNames from "classnames";
import './SearchTextbox.scss';

export const SearchTextbox = ({ value, onChange, className, label, placeholder, type='text', inputProps, ...props }) => {
    return (
        <div className={classNames("SearchTextbox", className)} {...props}>
            <input 
                placeholder={placeholder} 
                value={value} 
                onChange={onChange} 
                type={type} 
                className='input' 
                {...inputProps}
            />
        </div>
    );
}
