import React, {useState, useCallback, useContext, useMemo, useEffect, useRef} from 'react';
import './TideEntitySelect.scss';
import Select from "react-select";
import _ from "lodash";
import {useSelector} from 'react-redux';
import md5 from 'md5';
import classNames from 'classnames';
import {ApiContext} from "../../../services/api/api-config";
import {ReactComponent as SearchIcon} from "../../../assets/images/icons/search.svg";

/**
 *
 * @param value * The actual select value
 * @param onChange function The change handler, receives the entity object as then only parameter
 * @param entity string The entity to select, in plural as defined in the api
 * @param autoSelect If true, when the results are first loaded, the first option is selected. This forces preload
 * @param creatable boolean Whether new entities can be created from the input
 * @param createExtraParams object Parameters to be sent
 * @param maxResults Number Max number of results to show (It uses the api pagination, it's not enforced in the front end)
 * @param filterBy string Property of the entity to search for coincidences with the input
 * @param entityFromString function For the creatable option, it should create an object from the input string
 * @param isClearable If the user is able to erase selection
 * @param noOptionsMessage
 * @param labelCreator function It receives the entity and should return the string to show in the options
 * @param createPrefix string The string to show in the option that would create the new entity, prepended to the actual input
 * @param placeholder string The input placeholder
 * @param preload If true, the first page of entities will be loaded on mount
 * @param valueRenderer function Optional component to render the selected value, defaults to just the input string
 * @param optionRenderer function Optional component to render each option of the dropdown
 * @param disabled boolean Disable the input
 * @param filterLocal boolean If true, pagination is turned off, all results are loaded on mount and the filters are applied locally
 * @param additionalFilters object Filters to add to the request
 * @param filterEntities function A function to remove unwanted entities before they are displayed, it receives the options array and must return an array with the options to show
 * @param additionalApiOptions object Additional options when calling the api method
 * @param getMethod string Method to call from the api to fetch the entities
 * @param multi boolean If true, several entities can be selected and "value" should be an array
 * @param className string Class to add to the html
 * @param apiCustomProp string The key to save the results in Redux, defaults to 'TideEntitySelect'+entity
 * @param styles object An object with styles as described in https://react-select.com/styles
 * @returns {*}
 * @constructor
 */
const TideEntitySelect = ({
                          value,
                          onChange,
                          entity,
                          autoSelect=false,
                          creatable=false,
                          createExtraParams = {},
                          maxResults=10,
                          filterBy='name',
                          entityFromString=defaultEntityFromString,
                          isClearable=false,
                          noOptionsMessage=defaultNoOptionsMessage,
                          labelCreator= defaultLabelCreator,
                          createPrefix='Crear ',
                          placeholder='Escribe para buscar...',
                          preload=false,
                          valueRenderer,
                          optionRenderer,
                          disabled=false,
                          filterLocal=false,
                          additionalFilters=defaultAdditionalFilters,
                          filterEntities=_.identity,
                          additionalApiOptions=defaultAdditionalApiOptions,
                          getMethod = 'get',
                          multi=false,
                          className,
                          apiCustomProp,
                          styles,
                      })=>{

    if(autoSelect)
        preload=true;

    const api = useContext( ApiContext );
    const [inputValue, setInputValue] = useState('');

    const loadingId = '@TideEntitySelect.'+entity+'.get';

    const customProp =
        apiCustomProp ||
        ('TideEntitySelect.'+
            entity+
            md5(JSON.stringify(additionalFilters)+JSON.stringify(additionalApiOptions)));

    let options=useSelector(({api})=>api[customProp]||[]);
    const loading=useSelector(({loadingIds})=>!!loadingIds[loadingId]);

    const loadEntities = useCallback( ( input )=>{
        let params={...additionalFilters};

        if(!filterLocal)
            params={...params, [filterBy]: input, pageSize:maxResults};
        else
            params.pagination=false;

        api[entity][getMethod]({loadingId, params, customProp, ...additionalApiOptions});
    }, [api, entity, additionalFilters, filterLocal, filterBy, getMethod, loadingId, maxResults, customProp, additionalApiOptions]);

    const optionCount=options.length;

    const preloaded = useRef(false);
    useEffect(()=>{
        if((filterLocal && !optionCount) || (preload && !preloaded.current)) {
            preloaded.current = true;
            loadEntities();
        }
    },[loadEntities, filterLocal, optionCount, preload]);


    const inputChangeHandler= useMemo(() => _.debounce((input)=>{

        if(filterLocal) return;

        const trimmedInput = input.trim();
        setInputValue(trimmedInput);
        if( !trimmedInput ) return;

        loadEntities(trimmedInput);
    },650),[loadEntities, filterLocal]);

    //Creates object to send as value to the Creatable component
    const createValueForSelect = useCallback((entity)=>( { value: entity, label: labelCreator(entity) } ),[labelCreator]);
    //Converts the entity received as value prop to the Creatable component notation
    const selected = useMemo( ()=>{
        if( !multi )
            return value? createValueForSelect(value):null;
        return value && value.map? value.map(createValueForSelect) : [];
    }, [value, createValueForSelect, multi] );

    const onSelectChange = useCallback( ( option )=>{

        if( !option || (option.constructor !== Array && !option.value) )
            return onChange(null);

        if(multi){
            const selected = option.map( o=>o.value );
            onChange(selected);
        }
        else if(option.value.id === 'new'){
            api[entity].create({
                params:{
                    ...entityFromString(option.value.name),
                    ...createExtraParams
                },
                loadingId
            })
                .then(onChange);
        }
        else
            return onChange(option.value);

    }, [api, onChange, entity, entityFromString, loadingId, multi, createExtraParams] );

    //If a filter was sent, use it
    const filteredOptions=useMemo(()=>filterEntities(options),[filterEntities, options]);

    //Auto select first option, if enabled
    const autoSelected = useRef(false);
    useEffect(()=>{
        if(!autoSelected.current && autoSelect && filteredOptions?.length){
            autoSelected.current = true;
            onChange(filteredOptions[0]);
        }
    },[autoSelect, onChange, filteredOptions]);

    //Convert the options to the Select notation
    let optionsForSelect = useMemo( ()=>filteredOptions.map(createValueForSelect), [filteredOptions, createValueForSelect]);

    //Add the "Create new" option if creatable and there's no exact match (if there's an exact match we don't want to duplicate the info)
    optionsForSelect = useMemo( ()=>{

        if(!creatable)
            return optionsForSelect;

        const exactMatch=_.find(options, opt=>labelCreator(opt).toUpperCase() === inputValue.toUpperCase() );
        if(creatable && inputValue && !exactMatch )
            return [...optionsForSelect, {value:{id:'new', name:inputValue}, label: `${createPrefix} "${inputValue}"` }];
        return optionsForSelect;
    }, [options, creatable, inputValue, createPrefix, optionsForSelect, labelCreator]);

    const noOptions = useMemo(()=>typeof noOptionsMessage === 'function'? noOptionsMessage : ()=>noOptionsMessage , [noOptionsMessage] );

    return <Select
        className={classNames(className, 'TideEntitySelect', 'tide-react-select')}
        classNamePrefix='tide-react-select'
        isDisabled={disabled}
        filterOption={_.identity}
        isClearable={isClearable}
        isLoading={loading}
        isMulti={multi}
        name="entitySelect"
        noOptionsMessage={noOptions}
        onInputChange={inputChangeHandler}
        onChange={ onSelectChange }
        optionRenderer={optionRenderer}
        options={optionsForSelect}
        placeholder={placeholder}
        styles={styles}
        value={ selected }
        valueRenderer={valueRenderer}
        components={components}

    />;

};

export default TideEntitySelect;


const defaultLabelCreator=e=>e?e.name:'';
const defaultEntityFromString=s=>({name:s});
const defaultAdditionalFilters={};
const defaultAdditionalApiOptions={};
const defaultNoOptionsMessage=({inputValue})=>inputValue?'Sin resultados':'Escribe para buscar...';
const components = {
    DropdownIndicator: (props) => 
        <button className='DropdownIndicator'>
            <SearchIcon />
        </button>
}
