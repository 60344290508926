export const notificationTableSGroups = [
    'notification_read',
    'notification_read_id',
    'notification_read_html',
    'notification_read_link',
    'notification_read_read_date',
    'notification_read_created_date',
    'notifications_read_group_name',
    'notification_read_quantity',
    'notification_read_config',
    'notification_read_metadata',
    'notification_read_related_user',
    'user_read',
    'notification_read_notification_topic',
    'notification_topic_read',
    'notification_read_user',
    'read_created_by',
    'read_updated_by',
    'read_updated_date',
];

export const notificationDetailSGroups = [
    'notification_read',
    'notification_read_id',
    'notification_read_html',
    'notification_read_link',
    'notification_read_read_date',
    'notification_read_created_date',
    'notifications_read_group_name',
    'notification_read_quantity',
    'notification_read_config',
    'notification_read_metadata',
    'notification_read_related_user',
    'user_read',
    'notification_read_notification_topic',
    'notification_topic_read',
    'notification_read_user',
    'read_created_by',
    'read_updated_by',
    'read_updated_date',
];

export const notificationTableColumns = [
    {
        Header: 'id',
        accessor: 'id'
    },
     {
        Header: 'html',
        accessor: 'html'
    },
     {
        Header: 'link',
        accessor: 'link'
    },
     {
        Header: 'readDate',
        accessor: 'readDate'
    },
     {
        Header: 'createdDate',
        accessor: 'createdDate'
    },
     {
        Header: 'groupName',
        accessor: 'groupName'
    },
     {
        Header: 'quantity',
        accessor: 'quantity'
    },
     {
        Header: 'config',
        accessor: 'config'
    },
     {
        Header: 'metadata',
        accessor: 'metadata'
    },
     {
        Header: 'relatedUser',
        accessor: 'relatedUser?.id'
    },
     {
        Header: 'notificationTopic',
        accessor: 'notificationTopic?.id'
    },
     {
        Header: 'user',
        accessor: 'user?.id'
    },
     {
        Header: 'createdBy',
        accessor: 'createdBy?.id'
    },
     {
        Header: 'updatedBy',
        accessor: 'updatedBy?.id'
    },
     {
        Header: 'updatedDate',
        accessor: 'updatedDate'
    },
 ];

export const notificationDataDetailsArray = (notification) => [
    {data: notification?.id, dataLabel: 'id'},
    {data: notification?.html, dataLabel: 'html'},
    {data: notification?.link, dataLabel: 'link'},
    {data: notification?.readDate, dataLabel: 'readDate'},
    {data: notification?.createdDate, dataLabel: 'createdDate'},
    {data: notification?.groupName, dataLabel: 'groupName'},
    {data: notification?.quantity, dataLabel: 'quantity'},
    {data: notification?.config, dataLabel: 'config'},
    {data: notification?.metadata, dataLabel: 'metadata'},
    {data: notification?.relatedUser?.id, dataLabel: 'relatedUser'},
    {data: notification?.notificationTopic?.id, dataLabel: 'notificationTopic'},
    {data: notification?.user?.id, dataLabel: 'user'},
    {data: notification?.createdBy?.id, dataLabel: 'createdBy'},
    {data: notification?.updatedBy?.id, dataLabel: 'updatedBy'},
    {data: notification?.updatedDate, dataLabel: 'updatedDate'},
];


export const newNotificationFormDefinition = [
];

export const editNotificationFormDefinition = [
    ...newNotificationFormDefinition
];

export const prepareNewNotificationForServer = (form) => {
    const notification= {};


    return notification;
};

export const prepareEditedNotificationForServer = (form) => {
    return prepareNewNotificationForServer(form);
};

export const notificationToFormData = (notification) => {
    return {
        ...notification
    }
}

export const defaultIcon = null;

export const notificationIcons = {
    'EXPIRED_CERTITICATION': require('../../assets/images/icons/notifications/productors-notification.svg'),
    'ORDER': require('../../assets/images/icons/notifications/orders-notification.svg'),
}

export const notificationTitles = {
    'EXPIRED_CERTITICATION': 'Productors',
    'ORDER': 'My orders',
}

export const getNotificationIconAndTitle = ({notificationTopic}, t) => {
    return {
        icon: notificationIcons[notificationTopic?.name] || defaultIcon,
        title: t(notificationTitles[notificationTopic?.name])||''
    };
}

export const getHtmlNotificationFormatted = (html, t) => {
    let formatted = '';

    formatted = html.replace("{Certificate}", t('Certificate'));
    formatted = formatted.replace("{Please-update-the-document}", t('Please-update-the-document'));
    formatted = formatted.replace("{expired}", t('expired'));

    return formatted;
}