import fieldTypes from "../../components/FormModal/fields/fieldTypes";
import {
    copyPropertyOrNull, copyPropertyValueOrNull,
} from "../formUtils";

export const addressTableSGroups = [
    'address_read',
    'address_read_id',
    'address_read_street',
    'address_read_external_number',
    'address_read_internal_number',
    'address_read_latitude',
    'address_read_longitude',
    'address_read_neighbourhood',
    'neighbourhood_read',
    'address_read_postal_code',
    'read_created_by',
    'user_read',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const addressDetailSGroups = [
    'address_read',
    'address_read_id',
    'address_read_street',
    'address_read_external_number',
    'address_read_internal_number',
    'address_read_latitude',
    'address_read_longitude',
    'address_read_neighbourhood',
    'neighbourhood_read',
    'address_read_postal_code',
    'read_created_by',
    'user_read',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const addressTableColumns = [
    {
        Header: 'id',
        accessor: 'id'
    },
     {
        Header: 'street',
        accessor: 'street'
    },
     {
        Header: 'externalNumber',
        accessor: 'externalNumber'
    },
     {
        Header: 'internalNumber',
        accessor: 'internalNumber'
    },
     {
        Header: 'latitude',
        accessor: 'latitude'
    },
     {
        Header: 'longitude',
        accessor: 'longitude'
    },
     {
        Header: 'neighbourhood',
        accessor: 'neighbourhood?.id'
    },
     {
        Header: 'postalCode',
        accessor: 'postalCode'
    },
     {
        Header: 'createdBy',
        accessor: 'createdBy?.id'
    },
     {
        Header: 'updatedBy',
        accessor: 'updatedBy?.id'
    },
     {
        Header: 'createdDate',
        accessor: 'createdDate'
    },
     {
        Header: 'updatedDate',
        accessor: 'updatedDate'
    },
 ];

export const addressDataDetailsArray = (address) => [
    {data: address?.id, dataLabel: 'id'},
    {data: address?.street, dataLabel: 'street'},
    {data: address?.externalNumber, dataLabel: 'externalNumber'},
    {data: address?.internalNumber, dataLabel: 'internalNumber'},
    {data: address?.latitude, dataLabel: 'latitude'},
    {data: address?.longitude, dataLabel: 'longitude'},
    {data: address?.neighbourhood?.id, dataLabel: 'neighbourhood'},
    {data: address?.postalCode, dataLabel: 'postalCode'},
    {data: address?.createdBy?.id, dataLabel: 'createdBy'},
    {data: address?.updatedBy?.id, dataLabel: 'updatedBy'},
    {data: address?.createdDate, dataLabel: 'createdDate'},
    {data: address?.updatedDate, dataLabel: 'updatedDate'},
];


export const newAddressFormDefinition = [
    {
        name: 'street',
        label: 'street',
        required: true,
        type: fieldTypes.text
    },

    {
        name: 'externalNumber',
        label: 'externalNumber',
        required: true,
        type: fieldTypes.text
    },

    {
        name: 'internalNumber',
        label: 'internalNumber',
        required: false,
        type: fieldTypes.text
    },

    {
        name: 'latitude',
        label: 'latitude',
        required: false,
        type: fieldTypes.number
    },

    {
        name: 'longitude',
        label: 'longitude',
        required: false,
        type: fieldTypes.number
    },

    {
        name: 'neighbourhood',
        label: 'neighbourhood',
        required: true,
        type: fieldTypes.entity,
        entity: 'neighbourhoods'
    },

    {
        name: 'postalCode',
        label: 'postalCode',
        required: true,
        type: fieldTypes.text
    },

];

export const editAddressFormDefinition = [
    ...newAddressFormDefinition
];

export const prepareNewAddressForServer = (form) => {
    const address= {};

    copyPropertyOrNull(form, address, 'street');
    copyPropertyOrNull(form, address, 'externalNumber');
    copyPropertyOrNull(form, address, 'internalNumber');
    copyPropertyOrNull(form, address, 'latitude');
    copyPropertyOrNull(form, address, 'longitude');
    copyPropertyOrNull(form, address, 'postalCode');
    copyPropertyValueOrNull(form, address, 'state');
    copyPropertyValueOrNull(form, address, 'country');
    copyPropertyValueOrNull(form, address, 'municipality');
    copyPropertyValueOrNull(form, address, 'neighbourhood');

    //address.id = form.id;

    Object.keys(address).forEach(key => {
        if (address[key] === undefined || !address[key]) {
          delete address[key];
        }
    });

    return (Object.keys(address).length===0? null : address);
};

export const prepareEditedAddressForServer = (form) => {
    return prepareNewAddressForServer(form);
};

export const addressToFormData = (address) => {
    return {
        ...address,
        externalNumber: address?.externalNumber||'',
        internalNumber: address?.internalNumber||'',
        street: address?.street||'',
        neighbourhood: {label: address?.neighbourhood?.name||'', value: address?.neighbourhood?.id||''},
        municipality: {label: address?.neighbourhood?.municiaplity?.name||'', value: address?.neighbourhood?.municiaplity?.id||''},
        state: {label: address?.neighbourhood?.municiaplity?.state?.name||'', value: address?.neighbourhood?.municiaplity?.state?.id||''},
        country: {label: address?.neighbourhood?.municiaplity?.state?.country?.name||'', value: address?.neighbourhood?.municiaplity?.state?.country?.id||''},
    }
}

export const getAddressFromGoogleMapsAdressComponents = (addressComponents, neighborhood) => {
    const address = {};
    
    const street = addressComponents.address_components.find(component => component.types.includes('route'))||'';
    const externalNumber = addressComponents.address_components.find(component => component.types.includes('street_number'))||'';
    const postalCode = addressComponents.address_components.find(component => component.types.includes('postal_code'))||'';

    // format address data and save it to the address object
    address.street = street?.long_name;    
    address.country = {label: neighborhood?.municiaplity?.state?.country.name, value: neighborhood?.municiaplity?.state?.country.id};
    address.state = {label: neighborhood?.municiaplity?.state.name, value: neighborhood?.municiaplity?.state.id};
    address.municipality = {label: neighborhood?.municiaplity.name, value: neighborhood?.municiaplity.id};
    address.neighbourhood = {label: neighborhood.name, value: neighborhood.id};
    address.postalCode = postalCode?.long_name;
    address.externalNumber = externalNumber?.long_name;
    address.latitude = addressComponents.geometry.location.lat();
    address.longitude = addressComponents.geometry.location.lng();

    return address;
}