import React, {useMemo} from 'react';
import './HalfScreenLayout.scss';
import classNames from "classnames";

const HalfScreenLayout = ({ children, className, rightBackground }) => {

    const rightStyle = useMemo(()=>{
        return { backgroundImage: `url("${rightBackground}")` }
    }, [rightBackground]);

    const leftChild = children?.length? children[0] : children;
    const rightChild = children?.length > 1? children[1] : null;

    return (
        <div className={classNames("HalfScreenLayout container-fluid", className)}>
            <div className='row'>
                <div className='left-side side-container col-12 col-md-6 order-1'>
                    {leftChild}
                </div>
                <div className='right-side side-container col-12 col-md-6 order-md-1' style={rightStyle}>
                    {rightChild}
                </div>
            </div>
        </div>
    );
};

export default HalfScreenLayout;
