import React from 'react'
import {ReactComponent as ArroDownIcon} from "../../assets/images/icons/arrow-down.svg";
import './SortArrow.scss';

const SortArrow = ({ type='' }) => {
    return (
        <div className={`SortArrow__${type}`}>
            <ArroDownIcon />
        </div>
    );
}

export default SortArrow;