import React from 'react';
import DatePicker from 'react-date-picker';
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faArrowLeftLong,
    faArrowRight,
    faArrowRightLong  ,
} from "@fortawesome/free-solid-svg-icons";
import {ReactComponent as CalendarIcon} from "../../../../assets/images/icons/calendar.svg";
import './SearchBarDateFilter.scss';
import FieldErrorMessage from '../../../FieldErrorMessage/FieldErrorMessage';

const SearchBarDateFilter = ({ className, value, onChange, withIcon, label, DatepickerProps, showError=false, errorMessage, ...props }) => {
    
    const calendarIconValidClass = showError ? 'SearchBarDateFilter__calendar-icon--error' : '';
    const calendarValidClass = showError ? 'SearchBarDateFilter__calendar--error' : '';

    return <>
            <div className={classNames("SearchBarDateFilter", className, !!withIcon&&'SearchBarDateFilter--withIcon')} {...props}>
                <div className={classNames('SearchBarDateFilter__calendar-icon', calendarIconValidClass)}>
                    <CalendarIcon />
                </div>
                <div className={classNames('SearchBarDateFilter__calendar', calendarValidClass)}>
                    {!!label && <span className='SearchBarDateFilter__label'>{label}</span>} 
                    <DatePicker
                        locale={'es-mx'}
                        calendarIcon={false}
                        nextLabel={<FontAwesomeIcon icon={faArrowRight}/>}
                        next2Label={<FontAwesomeIcon icon={faArrowRightLong}/>}
                        prevLabel={<FontAwesomeIcon icon={faArrowLeft}/>}
                        prev2Label={<FontAwesomeIcon icon={faArrowLeftLong}/>}
                        value={value ? new Date(value) : null}
                        onChange={onChange}
                        placeholder={label}
                        {...DatepickerProps}
                    />
                </div>
            </div>
            {showError && <FieldErrorMessage message={errorMessage}/>}
        </>
}

export default SearchBarDateFilter;