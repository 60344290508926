import classNames from 'classnames';
import React from 'react'
import './Typography.scss';

const Typography = ({tag = 'span', size, alt, center, bold, caps, className, color, ...props }) => {
    const colorClass = color ? `text-${color}` : '';

    const Tag = tag;
    const sx = {
      fontFamily: alt ?? null,
      fontSize: size ?? null,
      fontWeight: bold ?? null,
      textAlign: center ? 'center' : null,
      textTransform: caps ? 'uppercase' : null
    };

    return <div className={classNames('Typography', colorClass, className)} style={sx} {...props} >
                <Tag {...props} style={sx} className={`${className} ${colorClass}`}/>
            </div>;
};

export default Typography;