import React, { useId } from 'react';

import './Switch.scss';

const Switch = ({
    id,
    name,
    value,
    onChange,
    optionLabels= ["", ""],
    disabled
}) => {
    const chkId = useId();

    if(!id && !name) {
        id= chkId;
        name= chkId;
    }

    const getDisabledClasses = () => {
        return disabled ? "toggle-switch-inner toggle-switch-disabled" : "toggle-switch-inner";
    }

    const getSpanDisabledClasses = () => {
        return disabled ? "toggle-switch-switch Switch-disabled" : "toggle-switch-switch";
    }

    return (
        <div className='Switch toggle-switch'>
            <input
                type="checkbox"
                name={name}
                className="toggle-switch-checkbox"
                id={id}
                checked={value}
                onChange={() => onChange(!value)}
                disabled={disabled}
            />
            {id ? (
                <label
                className="toggle-switch-label"
                htmlFor={id}
                >
                <span
                    className={getDisabledClasses()}
                    data-yes={optionLabels[0]}
                    data-no={optionLabels[1]}
                    tabIndex={-1}
                />
                <span
                    className={getSpanDisabledClasses()}
                    tabIndex={-1}
                />
                </label>
            ) : null}
        </div>
    );
}

export default Switch;