import React, { useContext } from 'react'
import {ReactComponent as FileIcon} from "../../assets/images/icons/file.svg";
import {ReactComponent as DeleteIcon} from "../../assets/images/icons/close.svg";
import Button from '../molecules/Button/Button';
import { ApiContext } from '../../services/api/api-config';

import './FilePreview.scss';

const FilePreview = ({file, onDelete}) => {
    const api = useContext(ApiContext);

    return (
        <div className='FilePreview__file-container'>
            <Button
                className="FilePreview__delete-button" 
                design="link"
                onClick={onDelete}
            >
                <DeleteIcon />
            </Button>
            <FileIcon className="FilePreview__file-icon" />

            <div className='FilePreview__file-name'>
                {file.id ? 
                    <a href={api.appFiles.getUrl(file)} 
                        target="_blank"
                        rel="noreferrer"
                    >
                        {file?.originalName}
                    </a> 
                        : 
                    file?.name}
            </div>
        </div>
    );
}

export default FilePreview