import React, { useEffect } from 'react';
import { createPortal } from "react-dom";
import classNames from "classnames";
import {ReactComponent as CloseIcon} from "../../assets/images/icons/x.svg";

import './Modal.scss';
import Button from '../molecules/Button/Button';
import { t } from 'i18next';
import Loader from '../Loader/Loader';

const Modal = ({
                   onClose,
                   children,
                   title,
                   className,
                   large,
                   mainButtonText= t('Continue'),
                   mainButtonColor='success',
                   mainButtonDisabled = false,
                   mainButtonAction,
                   secondaryButtonText=t('Cancel'),
                   secondaryButtonColor='gray',
                   secondaryButtonDisabled = false,
                   secondaryButtonAction,
                   handleTitleClick = null,
                   titleTooltip = null,
                   showBackArrow=false,
                   loading=false,
}) => {

    useEffect(() => {
        document.body.style.overflowY = 'hidden';

        return () =>{
          document.body.style.overflowY = 'auto';
        }
    }, [])

    return createPortal(<>
        <div className={classNames("Modal", large &&'large',className)} >
            <div className='back-overlay' onClick={onClose} />
                <div className='inner-modal'>
                    <CloseIcon 
                        onClick={onClose}
                        className={'close-btn'}
                    />

                    {title && <div className="modal-title">{title}</div>}

                    {loading ?  <Loader className="modal-loader" /> :
                        <div className='modal-content'>
                            {children}
                        </div>}

                    <div className='modal-footer'>
                        {secondaryButtonAction &&
                        <Button 
                            design={secondaryButtonColor} 
                            onClick={secondaryButtonAction} 
                            disabled={secondaryButtonDisabled}
                        >
                            {secondaryButtonText}
                        </Button>}
                        
                        {mainButtonAction &&
                        <Button 
                            design={mainButtonColor} 
                            onClick={mainButtonAction} 
                            disabled={mainButtonDisabled}
                        >
                            {mainButtonText}
                        </Button>}
                    </div>
                </div>
        </div>
    </>, document.body);
};

export default Modal;
