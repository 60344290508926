import React from 'react'
import './Indicator.scss'

const Indicator = ({value}) => {
    return (
        <div className='Indicator'>
            {value}
        </div>
    )
}

export default Indicator;