
export const moneyFormatter= ( money, currency, divider=1, ) =>{
    if( isNaN( Number(money) ) )
        return money;

    return (money/divider)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
        + (currency? ` ${currency}`:'');
};

export const numberFormatter = ( value ) =>{
    if( isNaN( Number(value) ) )
        return value;

    return (value+'').replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
};

export const pad = function(num, size=2) {
    let s = String(num);
    while (s.length < size) {s = "0" + s;}
    return s;
};
