import React from 'react'

import './Card.scss';

const Card = ({children, sx, className='', ...props}) => {
    return (
        <div className={`Card ${className}`} style={{...sx}}>
            {children}
        </div>
    )
}

export default Card;