import React from 'react'
import {ReactComponent as ErrorMessageIcon} from "../../assets/images/icons/message-error.svg";
import './FieldErrorMessage.scss';

const FieldErrorMessage = ({message}) => {
    return (
        <div className='FieldErrorMessage'>
            <ErrorMessageIcon />
            <span>{message}</span>
        </div>
    );
}

export default FieldErrorMessage;