import fieldTypes from "../../components/FormModal/fields/fieldTypes";

import {
    copyBoolean,
    copyPropertyOrThrow,
    copyPropertyOrNull,
    copyPropertyIdOrNull,
    copyPropertyValueOrNull,
    validateEmail,
    copyPropertyValueOrThrow,
    copyPropertyIdOrThrow
} from "../formUtils";
import i18next, { t } from "i18next";
import { prepareNewAddressForServer } from "./addressUtils";
import { getMaritalStatusOptions } from "./userUtils";

export const companyTableSGroups = [
    'company_read',
    'company_read_id',
    'company_read_type',
    'company_read_classification',
    'company_read_business_name',
    'company_read_trade_name',
    'company_read_contact_name',
    'company_read_contact_last_name',
    'company_read_contact_second_last_name',
    'company_read_contact_landline_phone_number',
    'company_read_contact_cellphone_number',
    'company_read_contact_email',
    'company_read_lead_code',
    'company_read_company_code',
    'company_read_registry_status',
    'company_read_rfc',
    'company_read_producing_products',
    'product_read',
    'company_read_address',
    'address_read',
    'company_read_orchards',
    'orchard_read',
    'company_read_company_products',
    'company_product_read',
    'company_read_bought_product_offers',
    'product_offer_read',
    'company_read_sold_product_offers',
    'company_read_file_archives',
    'file_archive_read',
    'company_read_interested_offers',
    'company_read_conversations',
    'conversation_read',
    'company_read_financing_profile',
    'company_read_export_program',
    'company_read_person_type',
    'company_read_tax_system',
    'tax_system_read',
    'company_read_digital_signature_file',
    'app_file_read',
    'company_read_digital_signature_key',
    'company_read_digital_signature_cer',
    'company_read_digital_seal_password',
    'company_read_digital_seal_key',
    'company_read_digital_seal_cer',
    'company_read_visit_files',
    'company_read_soil_analysis',
    'company_read_users',
    'user_read',
    'company_read_logo',
    'read_created_by',
    'read_updated_by',
    'read_created_date',
    'read_updated_date',
];

export const companyDetailSGroups = [
    'company_read',
    'user_read_id',
    'user_read_name',
    'user_read_last_name',
    'user_read_avatar',
    'user_read_birthday',
    'user_read_email',
    'read_file',
    
    'address_read',
    'tax_system_read',
    'company_read_address',
    'address_read_neighbourhood',
    'neighbourhood_read',
    'neighbourhood_read_municipality',
    'municipality_read',
    'municipality_read_state',
    'state_read',
];

export const companyWithOrchardsSGroups = [
    ...companyDetailSGroups,
    'company_read_orchards',
        'orchard_read_id',
        'orchard_read_name',
        'orchard_read_sectors',
            'sector_read_id',
            'sector_read_name',
]

export const companyForDropdopwnSGroups = [
    'company_read',
    'company_read_id',
    'company_read_type',
    'company_read_classification',
    'company_read_business_name',
    'company_read_trade_name',
    'company_read_contact_name',
    'company_read_contact_last_name',
    'company_read_contact_second_last_name',
    'company_read_contact_landline_phone_number',
    'company_read_contact_cellphone_number',
    'company_read_contact_email',
    'company_read_logo',
    'company_read_company_name',
    'company_read_address',
    'address_read',
    'read_file',
]

export const companyTableColumns = [
    {
        Header: 'id',
        accessor: 'id'
    },
    {
        Header: 'type',
        accessor: 'type'
    },
    {
        Header: 'classification',
        accessor: 'classification'
    },
    {
        Header: 'businessName',
        accessor: 'businessName'
    },
    {
        Header: 'tradeName',
        accessor: 'tradeName'
    },
    {
        Header: 'contactName',
        accessor: 'contactName'
    },
    {
        Header: 'contactFirstLastName',
        accessor: 'contactFirstLastName'
    },
    {
        Header: 'contactSecondLastName',
        accessor: 'contactSecondLastName'
    },
    {
        Header: 'contactLandlinePhoneNumber',
        accessor: 'contactLandlinePhoneNumber'
    },
    {
        Header: 'contactCellphoneNumber',
        accessor: 'contactCellphoneNumber'
    },
    {
        Header: 'contactEmail',
        accessor: 'contactEmail'
    },
    {
        Header: 'leadCode',
        accessor: 'leadCode'
    },
    {
        Header: 'companyCode',
        accessor: 'companyCode'
    },
    {
        Header: 'registryStatus',
        accessor: 'registryStatus'
    },
    {
        Header: 'rfc',
        accessor: 'rfc'
    },
    {
        Header: 'senasicaNumber',
        accessor: 'senasicaNumber'
    },
    {
        Header: 'producingProducts',
        accessor: 'producingProducts?.id'
    },
    {
        Header: 'address',
        accessor: 'address?.id'
    },
    {
        Header: 'orchards',
        accessor: 'orchards?.id'
    },
    {
        Header: 'companyProducts',
        accessor: 'companyProducts?.id'
    },
    {
        Header: 'boughtProductOffers',
        accessor: 'boughtProductOffers?.id'
    },
    {
        Header: 'soldProductOffers',
        accessor: 'soldProductOffers?.id'
    },
    {
        Header: 'fileArchives',
        accessor: 'fileArchives?.id'
    },
    {
        Header: 'interestedOffers',
        accessor: 'interestedOffers?.id'
    },
    {
        Header: 'conversations',
        accessor: 'conversations?.id'
    },
    {
        Header: 'financingProfile',
        accessor: 'financingProfile'
    },
    {
        Header: 'exportProgram',
        accessor: 'exportProgram'
    },
    {
        Header: 'personType',
        accessor: 'personType'
    },
    {
        Header: 'taxSystem',
        accessor: 'taxSystem?.id'
    },
    {
        Header: 'digitalSignatureFile',
        accessor: 'digitalSignatureFile?.id'
    },
    {
        Header: 'digitalSignatureKey',
        accessor: 'digitalSignatureKey?.id'
    },
    {
        Header: 'digitalSignatureCer',
        accessor: 'digitalSignatureCer?.id'
    },
    {
        Header: 'digitalSealPassword',
        accessor: 'digitalSealPassword'
    },
    {
        Header: 'digitalSealKey',
        accessor: 'digitalSealKey?.id'
    },
    {
        Header: 'digitalSealCer',
        accessor: 'digitalSealCer?.id'
    },
    {
        Header: 'visitFiles',
        accessor: 'visitFiles?.id'
    },
    {
        Header: 'soilAnalysis',
        accessor: 'soilAnalysis?.id'
    },
    {
        Header: 'users',
        accessor: 'users?.id'
    },
    {
        Header: 'logo',
        accessor: 'logo?.id'
    },
    {
        Header: 'createdBy',
        accessor: 'createdBy?.id'
    },
    {
        Header: 'updatedBy',
        accessor: 'updatedBy?.id'
    },
    {
        Header: 'createdDate',
        accessor: 'createdDate'
    },
    {
        Header: 'updatedDate',
        accessor: 'updatedDate'
    },
];

export const companyDataDetailsArray = (company) => [
    {data: company?.id, dataLabel: 'id'},
    {data: company?.type, dataLabel: 'type'},
    {data: company?.classification, dataLabel: 'classification'},
    {data: company?.businessName, dataLabel: 'businessName'},
    {data: company?.tradeName, dataLabel: 'tradeName'},
    {data: company?.contactName, dataLabel: 'contactName'},
    {data: company?.contactFirstLastName, dataLabel: 'contactFirstLastName'},
    {data: company?.contactSecondLastName, dataLabel: 'contactSecondLastName'},
    {data: company?.contactLandlinePhoneNumber, dataLabel: 'contactLandlinePhoneNumber'},
    {data: company?.contactCellphoneNumber, dataLabel: 'contactCellphoneNumber'},
    {data: company?.contactEmail, dataLabel: 'contactEmail'},
    {data: company?.leadCode, dataLabel: 'leadCode'},
    {data: company?.companyCode, dataLabel: 'companyCode'},
    {data: company?.registryStatus, dataLabel: 'registryStatus'},
    {data: company?.rfc, dataLabel: 'rfc'},
    {data: company?.senasicaNumber, dataLabel: 'senasicaNumber'},
    {data: company?.producingProducts?.id, dataLabel: 'producingProducts'},
    {data: company?.address?.id, dataLabel: 'address'},
    {data: company?.orchards?.id, dataLabel: 'orchards'},
    {data: company?.companyProducts?.id, dataLabel: 'companyProducts'},
    {data: company?.boughtProductOffers?.id, dataLabel: 'boughtProductOffers'},
    {data: company?.soldProductOffers?.id, dataLabel: 'soldProductOffers'},
    {data: company?.fileArchives?.id, dataLabel: 'fileArchives'},
    {data: company?.interestedOffers?.id, dataLabel: 'interestedOffers'},
    {data: company?.conversations?.id, dataLabel: 'conversations'},
    {data: company?.financingProfile, dataLabel: 'financingProfile'},
    {data: company?.exportProgram, dataLabel: 'exportProgram'},
    {data: company?.personType, dataLabel: 'personType'},
    {data: company?.taxSystem?.id, dataLabel: 'taxSystem'},
    {data: company?.digitalSignatureFile?.id, dataLabel: 'digitalSignatureFile'},
    {data: company?.digitalSignatureKey?.id, dataLabel: 'digitalSignatureKey'},
    {data: company?.digitalSignatureCer?.id, dataLabel: 'digitalSignatureCer'},
    {data: company?.digitalSealPassword, dataLabel: 'digitalSealPassword'},
    {data: company?.digitalSealKey?.id, dataLabel: 'digitalSealKey'},
    {data: company?.digitalSealCer?.id, dataLabel: 'digitalSealCer'},
    {data: company?.visitFiles?.id, dataLabel: 'visitFiles'},
    {data: company?.soilAnalysis?.id, dataLabel: 'soilAnalysis'},
    {data: company?.users?.id, dataLabel: 'users'},
    {data: company?.logo?.id, dataLabel: 'logo'},
    {data: company?.createdBy?.id, dataLabel: 'createdBy'},
    {data: company?.updatedBy?.id, dataLabel: 'updatedBy'},
    {data: company?.createdDate, dataLabel: 'createdDate'},
    {data: company?.updatedDate, dataLabel: 'updatedDate'},
];


export const newCompanyFormDefinition = [
    {
        name: 'type',
        label: 'type',
        required: true,
        type: fieldTypes.text
    },

    {
        name: 'classification',
        label: 'classification',
        required: true,
        type: fieldTypes.text
    },

    {
        name: 'businessName',
        label: 'businessName',
        required: true,
        type: fieldTypes.text
    },

    {
        name: 'tradeName',
        label: 'tradeName',
        required: true,
        type: fieldTypes.text
    },

    {
        name: 'contactName',
        label: 'contactName',
        required: true,
        type: fieldTypes.text
    },

    {
        name: 'contactFirstLastName',
        label: 'contactFirstLastName',
        required: true,
        type: fieldTypes.text
    },

    {
        name: 'contactSecondLastName',
        label: 'contactSecondLastName',
        required: true,
        type: fieldTypes.text
    },

    {
        name: 'contactLandlinePhoneNumber',
        label: 'contactLandlinePhoneNumber',
        required: true,
        type: fieldTypes.text
    },

    {
        name: 'contactCellphoneNumber',
        label: 'contactCellphoneNumber',
        required: true,
        type: fieldTypes.text
    },

    {
        name: 'contactEmail',
        label: 'contactEmail',
        required: true,
        type: fieldTypes.text
    },

    {
        name: 'rfc',
        label: 'rfc',
        required: false,
        type: fieldTypes.text
    },

    {
        name: 'senasicaNumber',
        label: 'senasicaNumber',
        required: false,
        type: fieldTypes.text
    },


    {
        name: 'address',
        label: 'address',
        required: false,
        type: fieldTypes.entity,
        entity: 'addresses'
    },



    {
        name: 'financingProfile',
        label: 'financingProfile',
        required: false,
        type: fieldTypes.text
    },

    {
        name: 'exportProgram',
        label: 'exportProgram',
        required: false,
        type: fieldTypes.boolean
    },

    {
        name: 'personType',
        label: 'personType',
        required: false,
        type: fieldTypes.text
    },

    {
        name: 'taxSystem',
        label: 'taxSystem',
        required: false,
        type: fieldTypes.entity,
        entity: 'taxSystems'
    },

    {
        name: 'digitalSignatureFile',
        label: 'digitalSignatureFile',
        required: false,
        type: fieldTypes.entity,
        entity: 'appFiles'
    },

    {
        name: 'digitalSignatureKey',
        label: 'digitalSignatureKey',
        required: false,
        type: fieldTypes.entity,
        entity: 'appFiles'
    },

    {
        name: 'digitalSignatureCer',
        label: 'digitalSignatureCer',
        required: false,
        type: fieldTypes.entity,
        entity: 'appFiles'
    },

    {
        name: 'digitalSealPassword',
        label: 'digitalSealPassword',
        required: false,
        type: fieldTypes.text
    },

    {
        name: 'digitalSealKey',
        label: 'digitalSealKey',
        required: false,
        type: fieldTypes.entity,
        entity: 'appFiles'
    },

    {
        name: 'digitalSealCer',
        label: 'digitalSealCer',
        required: false,
        type: fieldTypes.entity,
        entity: 'appFiles'
    },


    {
        name: 'soilAnalysis',
        label: 'soilAnalysis',
        required: false,
        type: fieldTypes.entity,
        entity: 'appFiles'
    },

    {
        name: 'logo',
        label: 'logo',
        required: false,
        type: fieldTypes.entity,
        entity: 'appFiles'
    },

];

export const editCompanyFormDefinition = [
    ...newCompanyFormDefinition
];

export const prepareNewCompanyForServer = (form) => {
    const company= {};

    copyPropertyOrThrow(form, company, 'type', 'Debes agregar type de company');
    copyPropertyOrThrow(form, company, 'classification', 'Debes agregar classification de company');
    copyPropertyOrThrow(form, company, 'businessName', 'Debes agregar businessName de company');
    copyPropertyOrThrow(form, company, 'tradeName', 'Debes agregar tradeName de company');
    copyPropertyOrThrow(form, company, 'contactName', 'Debes agregar contactName de company');
    copyPropertyOrThrow(form, company, 'contactFirstLastName', 'Debes agregar contactFirstLastName de company');
    copyPropertyOrThrow(form, company, 'contactSecondLastName', 'Debes agregar contactSecondLastName de company');
    copyPropertyOrThrow(form, company, 'contactLandlinePhoneNumber', 'Debes agregar contactLandlinePhoneNumber de company');
    copyPropertyOrThrow(form, company, 'contactCellphoneNumber', 'Debes agregar contactCellphoneNumber de company');
    copyPropertyOrThrow(form, company, 'contactEmail', 'Debes agregar contactEmail de company');
    copyPropertyOrNull(form, company, 'rfc');
    copyPropertyOrNull(form, company, 'senasicaNumber');
    copyPropertyIdOrNull(form, company, 'producingProducts', 'Debes agregar producingProducts de company');
    copyPropertyIdOrNull(form, company, 'address', 'Debes agregar address de company');
    copyPropertyIdOrNull(form, company, 'orchards', 'Debes agregar orchards de company');
    copyPropertyIdOrNull(form, company, 'companyProducts', 'Debes agregar companyProducts de company');
    copyPropertyOrNull(form, company, 'financingProfile');
    copyBoolean(form, company, 'exportProgram');
    copyPropertyOrNull(form, company, 'personType');
    copyPropertyIdOrNull(form, company, 'taxSystem', 'Debes agregar taxSystem de company');
    copyPropertyIdOrNull(form, company, 'digitalSignatureFile', 'Debes agregar digitalSignatureFile de company');
    copyPropertyIdOrNull(form, company, 'digitalSignatureKey', 'Debes agregar digitalSignatureKey de company');
    copyPropertyIdOrNull(form, company, 'digitalSignatureCer', 'Debes agregar digitalSignatureCer de company');
    copyPropertyOrNull(form, company, 'digitalSealPassword');
    copyPropertyIdOrNull(form, company, 'digitalSealKey', 'Debes agregar digitalSealKey de company');
    copyPropertyIdOrNull(form, company, 'digitalSealCer', 'Debes agregar digitalSealCer de company');
    copyPropertyIdOrNull(form, company, 'visitFiles', 'Debes agregar visitFiles de company');
    copyPropertyIdOrNull(form, company, 'soilAnalysis', 'Debes agregar soilAnalysis de company');
    copyPropertyIdOrNull(form, company, 'logo', 'Debes agregar logo de company');

    return company;
};

export const prepareEditedCompanyForServer = (form) => {
    return prepareNewCompanyForServer(form);
};

export const companyToFormData = (company, t) => {
    let birthday = null;

    if(company.mainContact?.birthday){
        birthday = new Date(company.mainContact?.birthday);
        birthday.setMinutes(birthday.getMinutes() + birthday.getTimezoneOffset())
    }

    const data = {
        ...company,
        financingProfile: financingProfilesOptions.find(option => option.value === company.financingProfile),
        exportProgram: getExportProgramOptions(t).find(option => option.value === company.exportProgram),
        personType: personTypeOptions.find(option => option.value === company.personType),
        taxSystem: company.taxSystem?.id ? { value: company.taxSystem?.id, label: company.taxSystem?.name} : null,
        postalCode: company.address?.postalCode,
        neighbourhood: company.address?.neighbourhood?.id ? { value: company.address?.neighbourhood?.id, label: company.address?.neighbourhood?.name} : null,
        municipality: company.address?.neighbourhood?.municiaplity?.id ? { value: company.address?.neighbourhood?.municiaplity?.id, label: company.address?.neighbourhood?.municiaplity?.name} : null,
        state: company.address?.neighbourhood?.municiaplity?.state?.id ? { value: company.address?.neighbourhood?.municiaplity?.state?.id, label: company.address?.neighbourhood?.municiaplity?.state?.name} : null,
        street: company.address?.street,
        externalNumber: company.address?.externalNumber,
        internalNumber: company.address?.internalNumber,
        dependants: JSON.parse(company.dependants)||[],
        maritalStatus: getMaritalStatusOptions(t).find(option => option.value === company.maritalStatus),
        mainContactName: company.mainContact?.name,
        mainContactLastName: company.mainContact?.lastName,
        mainContactEmail: company.mainContact?.email,
        birthday: birthday,
        avatar: company.mainContact?.avatar
    };

    return data;
}

export const companyTypes = {
    TYPE_GROWER: 'TYPE_GROWER',
    TYPE_PRODUCER: 'TYPE_PRODUCER',
    TYPE_WHOLESALE: 'TYPE_WHOLESALE'
}

export const companyStatus = {
    REGISTRY_STATUS_PENDING: 'REGISTRY_STATUS_PENDING',
	REGISTRY_STATUS_PROSPECT: 'REGISTRY_STATUS_PROSPECT',
	REGISTRY_STATUS_PRODUCER: 'REGISTRY_STATUS_PRODUCER',
	REGISTRY_STATUS_DISENROLL: 'REGISTRY_STATUS_DISENROLL'
}

export const companyStatusTrans = {
    REGISTRY_STATUS_PENDING: i18next.t('REGISTRY_STATUS_PENDING'),
    REGISTRY_STATUS_PROSPECT: i18next.t('REGISTRY_STATUS_PROSPECT'),
    REGISTRY_STATUS_PRODUCER: i18next.t('REGISTRY_STATUS_PRODUCER'),
    REGISTRY_STATUS_DISENROLL: i18next.t('REGISTRY_STATUS_DISENROLL'),
}

export const companyStatusOptions = [
    {
        value: companyStatus.REGISTRY_STATUS_PENDING,
        label: companyStatusTrans.REGISTRY_STATUS_PENDING
    },
    {
        value: companyStatus.REGISTRY_STATUS_PROSPECT,
        label: companyStatusTrans.REGISTRY_STATUS_PROSPECT
    },
    {
        value: companyStatus.REGISTRY_STATUS_PRODUCER,
        label: companyStatusTrans.REGISTRY_STATUS_PRODUCER
    },
    {
        value: companyStatus.REGISTRY_STATUS_DISENROLL,
        label: companyStatusTrans.REGISTRY_STATUS_DISENROLL
    }
];

export const getCompanyStatusTrans = (status) => companyStatusTrans[status]||status;

export const growerTableSGroups = [
    'company_read',
    'user_read',
    'read_file'
];

export const getLogoUrl = ( company, api )=>{
    return company?.logo ? api?.appFiles.getUrl(company.logo) : null;
};

export const financingProfilesOptions = [
    {label: 'A', value: 'A'},
    {label: 'B', value: 'B'},
    {label: 'C', value: 'C'},
];

export const exportProgramOptions = [
    {label: 'Yes', value: true},
    {label: 'No', value: false}
];

export const getExportProgramOptions = (t) => exportProgramOptions.map(option => ({
    ...option,
    label: t(option.label)
}));

export const personTypeOptions = [
    {value: 'Persona-fisica', label: 'Persona física'},
    {value: 'Regimen-Simplificado-de-Confianza', label: 'Régimen Simplificado de Confianza'},
    {value: 'Regimen-de-Actividades-Plataformas-Tecnologicas', label: 'Régimen de Actividades Empresariales con ingresos a través de Plataformas Tecnológicas.'},
    {value: 'Regimen-de-Arrendamiento', label: 'Régimen de Arrendamiento'},
    {value: 'Regimen-de-Empresariales-Profesionales', label: 'Régimen de Actividades Empresariales y Profesionales'},
    {value: 'Persona-moral', label: 'Persona moral'},    
];

export const REGISTRY_STATUS_PENDING = 'REGISTRY_STATUS_PENDING';
export const REGISTRY_STATUS_PROSPECT = 'REGISTRY_STATUS_PROSPECT';
export const REGISTRY_STATUS_PRODUCER = 'REGISTRY_STATUS_PRODUCER';
export const REGISTRY_STATUS_DISENROLL = 'REGISTRY_STATUS_DISENROLL';

export const prepareGrowerForServerStep = (form) => {
    const grower= {};

    // Create account
    copyPropertyOrThrow(form, grower, 'companyName', t('You-must-add-the-name-of-the-company'));
    //copyPropertyOrThrow(form, grower, 'contactEmail', t("missing-email-field"));
    copyPropertyOrNull(form, grower, 'contactEmail');

    copyPropertyIdOrNull(form, grower, 'contactCellphoneNumber');
    copyPropertyIdOrNull(form, grower, 'birthday');
    copyPropertyValueOrNull(form, grower, 'financingProfile');
    copyPropertyValueOrNull(form, grower, 'exportProgram');

    // fiscal information
    copyPropertyIdOrNull(form, grower, 'businessName');
    copyPropertyIdOrNull(form, grower, 'rfc');
    copyPropertyValueOrNull(form, grower, 'personType');
    copyPropertyValueOrNull(form, grower, 'taxSystem');

    // Validations:
    if(grower.contactEmail)
        validateEmail(grower.contactEmail, t("email-format-error"));

    // address
    const address = prepareNewAddressForServer(form);
    
    if(address && Object.keys(address).length > 0)
        grower.address = address;

    // principal contact information
    copyPropertyIdOrNull(form, grower, 'contactName');
    copyPropertyIdOrNull(form, grower, 'contactFirstLastName');
    copyPropertyValueOrNull(form, grower, 'maritalStatus');
    copyPropertyIdOrNull(form, grower, 'hasFamily');
    copyPropertyIdOrNull(form, grower, 'hasVehicle');    
    copyPropertyIdOrNull(form, grower, 'useOfTechnology');
    copyPropertyIdOrNull(form, grower, 'otherActivities');
    copyPropertyIdOrNull(form, grower, 'otherComments');
    copyPropertyIdOrNull(form, grower, 'digitalSealPassword');
    
    grower.dependants = JSON.stringify(getDependantsArray(form.dependants));
    grower.type = companyTypes.TYPE_GROWER;

    Object.keys(grower).forEach(key => {
        if (grower[key] === undefined) {
          delete grower[key];
        }
    });

    return grower;
}

export const prepareFilesForServer = (form) => {
    const files = {};

    // documents
    files['ciec'] = getFileFromForm(form, 'ciec');
    files['billingConstancy'] = getFileFromForm(form, 'billingConstancy');
    files['contitutionalAct'] = getFileFromForm(form, 'contitutionalAct');
    files['rppycLegalRepresentant'] = getFileFromForm(form, 'rppycLegalRepresentant');
    files['rppycRegistration'] = getFileFromForm(form, 'rppycRegistration');

    // Billing data files
    files['digitalSignatureFile'] = getFileFromForm(form, 'digitalSignatureFile');
    files['digitalSignatureKey'] = getFileFromForm(form, 'digitalSignatureKey');
    files['digitalSignatureCer'] = getFileFromForm(form, 'digitalSignatureCer');
    files['digitalSealKey'] = getFileFromForm(form, 'digitalSealKey');
    files['digitalSealCer'] = getFileFromForm(form, 'digitalSealCer');

    Object.keys(files).forEach(key => {
        if (files[key] === undefined || files[key] === null) {
          delete files[key];
        }
    });

    return files;
}

/**
 * It returns the first file from a form, or null if the form doesn't have a file with the given name
 * @param form - The form object that was passed to the route handler
 * @param name - The name of the file input field in the form.
 * @returns The first file in the form with the name of the parameter.
 */
export const getFileFromForm = (form, name) => {
    if(form && name && form[name]?.length>0)
        return form[name][0] ? form[name][0].raw : null;

    if(form && name && form[name])
        return form[name] ? form[name].raw : null;
                
    return null;
}

/**
 * It takes an array of dependants, filters out any null values, and returns an array of the dependant
 * values.
 * @param dependants - The dependants array from the state.
 */
export const getDependantsArray = (dependants) => dependants?.filter(dep => dep).map(dependant => dependant.value);

export const prepareGrowerForServerStep2 = (form, t) => {
    const grower= {};

    copyPropertyValueOrThrow(form, grower, 'maritalStatus', t('missing-marital-status-field'));
    copyPropertyIdOrThrow(form, grower, 'birthday', t('missing-birthday-field'));
    copyPropertyIdOrNull(form, grower, 'hasFamily');
    copyPropertyIdOrNull(form, grower, 'hasVehicle');    
    copyPropertyIdOrThrow(form, grower, 'otherActivities', t('missing-other-activities-field'));
    copyPropertyIdOrNull(form, grower, 'otherComments');
    copyPropertyIdOrNull(form, grower, 'digitalSealPassword');
    copyPropertyIdOrNull(form, grower, 'hasComputer');
    copyPropertyIdOrNull(form, grower, 'hasEmail');
    copyPropertyIdOrNull(form, grower, 'hasInternet');
    copyPropertyIdOrNull(form, grower, 'hasSmartphone');
    copyPropertyIdOrNull(form, grower, 'hasDataPlan');
    copyPropertyIdOrNull(form, grower, 'useWhatsapp');
    
    grower.dependants = JSON.stringify(getDependantsArray(form.dependants));
    
    Object.keys(grower).forEach(key => {
        if (grower[key] === undefined) {
          delete grower[key];
        }
    });

    return grower;
}