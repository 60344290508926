import i18next, {t} from 'i18next';
import ImageWithLabel from '../../components/Dashboard/ImageWithLabel/ImageWithLabel';
import {copyPropertyOrNull, copyPropertyOrThrow, validateEmail} from "../formUtils";
import { companyTypes, getLogoUrl } from './companyUtils';

export const ROLE_GROWER = 'grower';
export const ROLE_WHOLESALE = 'wholesale';
export const ROLE_MIAMERKATO_ADMIN = 'ROLE_MIAMERKATO_ADMIN';
export const SUPER_ADMIN = 'SUPER_ADMIN';

export const ADMIN_ROLE_ID = 1;
export const GENERAL_ADMIN_ROLE_ID = 2;
export const DEFAULT_USER_ROLE = 3;

export const prepareUserFromRegister = ( form, role )=>{
    const user = {};
    
    copyPropertyOrThrow(form, user, 'name', t("missing-name-field"));
    copyPropertyOrThrow(form, user, 'lastName', t("missing-lastname-field"));
    copyPropertyOrThrow(form, user, 'email', t("missing-email-field"));
    copyPropertyOrThrow(form, user, 'password', t("missing-password-field"));

    if(form.password !== form.confirmPass)
        throw new Error(t("passwords-are-different"));
  
    if(role===ROLE_GROWER) {
        user.company = {
            type: companyTypes.TYPE_GROWER
        }
    }

    if(role===ROLE_WHOLESALE) {
        user.company = {
            type: companyTypes.TYPE_WHOLESALE
        }
    }

    // Set as main contact for company 
    user.isMainContact = true;

    return user;
};

export const getUserLabelWithAvatar = (company, api) => {
    const logo = getLogoUrl(company, api);

    return (logo ? <ImageWithLabel 
                        image={<img src={logo} 
                        alt={company.companyName} />} 
                        label={company.companyName} 
                        align={'left'} 
                    /> : company.companyName);
}

export const maritalStatuses = {
    Married: 'Married',
    Widowed: 'Widowed',
    Separated: 'Separated',
    Divorced: 'Divorced',
    Single: 'Single'
};

export const maritalStatusesOptions = Object.entries(maritalStatuses).map(([key, value]) => ({
    value: value,
    label: value
}));

export const getMaritalStatusOptions = (t) => {
    return maritalStatusesOptions.map(option => ({
        value: option.value,
        label: t(option.label)
    }));
}

export const dependants = {
    FATHER: 'FATHER',
    MOTHER: 'MOTHER',
    SON: 'SON',
    DAUGHTER: 'DAUGHTER',
    BROTHER: 'BROTHER',
    SISTER: 'SISTER',
    HUSBAND: 'HUSBAND',
    WIFE: 'WIFE'
};

export const dependantsOptions = Object.entries(dependants).map(([key, value]) => {
    return {
        value: value,
        label: i18next.t(value)||value
    }
});

export const prepareUserFilesForServer = (form) => {
    const files = {};

    if(form.avatar && !form.avatar?.id) files['avatar'] = form.avatar;
    
    Object.keys(files).forEach(key => {
        if (files[key] === undefined || files[key] === null) {
          delete files[key];
        }
    });

    return files;
}

export const getFileFromForm = (form, name) => {
    if(form[name])
        return form[name][0].raw;
    
    return null;
}

export const prepareUserFromNewGrower = ( form )=>{
    const user = {};
    
    copyPropertyOrThrow(form, user, 'mainContactName', 'Contact name is missing', 'name');
    copyPropertyOrThrow(form, user, 'mainContactLastName', 'Contact lastname is missing', 'lastName');
    copyPropertyOrThrow(form, user, 'mainContactEmail', 'Contact email is missing', 'email');
    copyPropertyOrNull(form, user, 'birthday');        

    // Validations:
    validateEmail(user.email, t("contact-email-format-error"));

    user.isMainContact = true;

    return user;
};

export const userListSGroups = [
    'user_read_id',
    'user_read_name',
    'user_read_last_name',
    'user_read_email'
];

export const usersTableSGroups = [
    ...userListSGroups,
    'user_read_phone',
    'user_read_created_date',
    'user_read_avatar',
    'user_read_is_active',
    'read_file',
    'user_read_role',
        'role_read',
    'user_read_permissions',
        'permission_read'
];

export const usersWithPermissionsGSGroups = [
    ...userListSGroups,
    'user_read_phone',
    'user_read_created_date',
    'user_read_avatar',
    'user_read_is_active',
    'read_file',
    'user_read_role',
        'role_read',
    'user_read_permission_groups',
        'permission_group_read',
        'permission_read',
];

export const usersDetailsSGroups = [
    ...usersTableSGroups
];

export const getEmptyUser = () => {
    return {
        id: null,
        name: '',
        lastName: '',
        phone: '',
        email: '',
        role: DEFAULT_USER_ROLE
    }
}

export const prepareUserToServer = (form, t) => {
    const user = {};

    copyPropertyOrThrow(form, user, 'name', 'Name is missing');
    copyPropertyOrThrow(form, user, 'lastName', 'Last name is missing');
    copyPropertyOrThrow(form, user, 'email', 'Email is missing');
    copyPropertyOrThrow(form, user, 'phone', 'Phone is missing');
    
    user.role = form.isAdmin ? GENERAL_ADMIN_ROLE_ID : DEFAULT_USER_ROLE;
    user.username = form.email;

    return user;
}

export const userToForm = (user) => {
    return {
        ...user,
        isAdministrator: (user.role?.id === ADMIN_ROLE_ID || user.role?.id === GENERAL_ADMIN_ROLE_ID)
    }
}