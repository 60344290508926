import classNames from 'classnames';
import {useTranslation} from "react-i18next";
import React from 'react'
import {ReactComponent as MessageSuccessIcon} from "../../../assets/images/icons/message-success.svg";
import './Message.scss';

const Message = ({className='', showIcon=true, icon, showTitle=true, title, type='success', children}) => {
    const classType = `Message--${type}`;
    const {t} = useTranslation();
    const messageIcon = ( type==='success' ? <MessageSuccessIcon /> : null );
    
    return (
        <div className={classNames('Message', className, classType)}>
            {showIcon &&
                <div className='Message__icon'>
                    {icon||messageIcon}
                </div>}
            <div className='Message__content'>
                {showTitle && 
                    <div className='Message__title bold'>
                        {title||t('Important')}
                    </div>}
                {children}
            </div>            
        </div>
    );
}

export default Message;