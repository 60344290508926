import React from 'react';
import './TideReactTable.scss';
import Pagination from "./components/Pagination/Pagination";
import classNames from 'classnames';
import SortArrow from '../../SortArrow/SortArrow';
import Loader from '../../Loader/Loader';

const TideReactTable = ({className, reactTable, onRowClick, loading, totalItems, pageSizeOptions, displayPagination=true, withPadding=false}) => {
    return (
        <div className={classNames("TideReactTable", className)}>

            {loading ? 
                <Loader /> 
                : 
                <div className='table-container table-responsive'>
                    <table {...reactTable.getTableProps()} className={!withPadding ? 'table' : 'table table-padding' }>
                        <thead>
                        {reactTable.headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                <div className='table-header-with-order-icon'>
                                                    {column.render('Header')}
                                                    
                                                    {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <SortArrow type='desc' />
                                                        : <SortArrow type='asc' />
                                                    : ""}
                                                </div>
                                            </th>
                                        ))}
                                </tr>
                            ))}
                        </thead>

                        <tbody {...reactTable.getTableBodyProps()}>
                        
                        {reactTable.page.map(row => {
                            reactTable.prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} onClick={ onRowClick && ((e)=>onRowClick(row.original, e)) }>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>}

            {!reactTable.rows?.length &&
            <div className='spacer' />}

            {/* {loading &&  <div className={'over-message'}><LoadingIcon/></div>} */}

            {(!reactTable.rows?.length && !loading)&&
                <div className={'over-message'}>
                    <span>No se encontró ningún resultado.</span>
                </div>}
            {displayPagination &&
            <div className={'table-footer'}>
                <Pagination reactTable={reactTable} totalItems={totalItems} pageSizeOptions={pageSizeOptions}/>
            </div>
            }
        </div>
    );
};

export default TideReactTable;