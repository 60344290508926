const fieldTypes = {
    text: 'text',
    date: 'date',
    time: 'time',
    number: 'number',
    boolean: 'boolean',
    entity: 'entity',
    dropdown: 'dropdown',
};

export default fieldTypes;

