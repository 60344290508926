import {useState, useMemo, useContext, useCallback, useEffect} from 'react';
import _ from "lodash";
import {useSelector} from "react-redux";
import {useTable, usePagination, useSortBy} from "react-table";
import {ApiContext} from "../../../services/api/api-config";

const emptyArray = [];

/**
 *
 * @param active boolean Default to true. If false it will not load anything from the server
 * @param entity string The name of the entity to fetch, in plural as defined in the Api
 * @param columns [] Array to send to ReactTable as columns property
 * @param debounced boolean Whether the load function should be debounced, handy when loading on each keystroke of a filter
 * @param requestFilters object Extra filters to send to the api in the request
 * @param requestOptions object Merged to the api call param
 * @param createApiOptions function Receives the params (tableState, addOptions, config, filters) and should return the object
 *                           to send to the api call see tableStateToApiOptions for the default implementation
 * @param getMethod string Method to call on the api endpoint
 * @param filterData function A function to filter the data received from the server before processing it
 * @param filterMappings object The default tableStateToApiOptions will take this in the config object and convert each
 *                           filter named as each key of the filterMappings object and convert it to its value
 * @returns {{reload: (function(): Q.Promise<any> | Promise<void> | * | void | PromiseLike<any>), itemsFoundString: string, tableProps: {reactTable: *, manualPagination: boolean, onFetchData: (*), defaultFilterMethod: (function(*, *): boolean), loading: boolean}, lastUsedApiOptions: unknown, reduxProp: string}}
 */
const useTideTable = ({
                    active=true,
                    entity,
                    columns,
                    debounced=true,
                    requestFilters,
                    requestOptions,
                    createApiOptions=tableStateToApiOptions,
                    getMethod='get',
                    filterData,
                    initialFilters={},
                    initialPage = 1,
                    initialItemsPerPage = 10,
                })=> {

    if (!entity)
        throw new Error('The "entity" parameter is mandatory for the "useTideTable" hook.');
    if (!columns)
        throw new Error('The "columns" parameter is mandatory for the "useTideTable" hook.');

    //  -------- Get table state ----------

    //Get the requested data from Redux
    const reduxProp = requestOptions?.customProp? requestOptions.customProp:entity;
    let data = useSelector(({api})=>api[reduxProp])||emptyArray;

    if(initialPage<1){
        initialPage=1;
    }

    if(initialItemsPerPage<1 || !typeof Number(initialItemsPerPage) === 'number'){
        initialItemsPerPage=10;
    }

    //Get the request meta data from redux
    let {totalItems, itemsPerPage} = useSelector(({api})=>api[reduxProp+'Meta'])||{totalItems:0, itemsPerPage:initialItemsPerPage};
    const pageCount=Math.ceil(totalItems/itemsPerPage)||1;

    // State to implement filter inputs
    const [filters, setFilters] = useState( initialFilters );

    // default disable sorting
    columns = useMemo(() => columns.map(column => {
        const disableSortBy = (typeof column.disableSortBy === 'undefined' ? true : column.disableSortBy);
        return {
            ...column,
            disableSortBy
        }
    }), [columns]);
    
    //Table state from ReactTable
    const reactTable = useTable({
            columns,
            data,
            manualPagination:true,
            manualGlobalFilter: true,
            pageCount,
            initialState: {
                pageSize: initialItemsPerPage,
                pageIndex: initialPage-1
            },
            manualSortBy: true
        },
        useSortBy,
        usePagination        
    );

    const {pageIndex, pageSize, sortBy} = reactTable.state;

    //Generate a unique loading id for this component
    let loadingId = useMemo(()=>`useTideTable.${entity}.${Math.random()}`,[entity]);

    if(requestOptions && requestOptions.loadingId)
        loadingId=requestOptions.loadingId;
    //Get the api object
    const api = useContext(ApiContext);

    const [lastUsedApiOptions, setLastUsedApiOptions]=useState(null);

    //Function to fetch data from server, debounced for searching while writing an input
    const callApi = useMemo(()=>{
        const _callApi = (apiOptions)=>{
            if(!active)
                return;
            //Make the request
            return api[entity][getMethod](apiOptions).then(()=> setLastUsedApiOptions(apiOptions));
        };
        if(debounced)
            return _.debounce(_callApi, 650)
        return _callApi;
    },[api, active, entity, getMethod, debounced]);

    const loadData = useCallback( () => {
        const sortByData = sortBy[0];     
        let orderFilter = {};
        if(sortByData) orderFilter[`order[${sortByData?.id}]`] = sortByData.desc ? 'desc' : 'asc';

        //Convert the table state to an api options object to make a request
        const apiOptions=createApiOptions({
            pageIndex,
            pageSize,
            apiOptions:{loadingId, ...requestOptions},
            requestFilters: { ...requestFilters, ...filters, ...orderFilter},
        });
        apiOptions.customProp = reduxProp;
        callApi(apiOptions);
    }, [createApiOptions, requestOptions, pageIndex, pageSize, loadingId, requestFilters, callApi, filters, reduxProp, sortBy ]);

    //If the parameters for the request change, we reload the data
    useEffect(loadData,[loadData, sortBy]);

    //Get the loading state of the request from redux
    const loading = !!useSelector(({loadingIds})=>loadingIds[loadingId]);

    const filterCaseInsensitive=(filter, row)=>{
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                :
                true
        );
    };

    //Apply external filter to data array
    data=useMemo(()=>{
        if(filterData)
            return filterData(data);
        return data;
    }, [filterData, data]);

    //Expose everything in an object
    //tableProps are the props meant to be sent to a ReactTable

    return useMemo(()=>({
        tableProps: {
            reactTable,
            data,
            loading,
            manualPagination: true,
            totalItems,
            defaultFilterMethod:filterCaseInsensitive,
        },
        reduxProp,
        reload: loadData,
        pageIndex,
        itemsFoundString:`${totalItems} registro${totalItems!==1?'s':''} encontrado${totalItems!==1?'s':''}`,
        lastUsedApiOptions,
        filters,
        setFilters,
        sortBy
    }),[reactTable, totalItems, loadData, loading, reduxProp, lastUsedApiOptions, data, pageIndex, filters, sortBy]);

};
/*
export const filtersToObject = ( filters = [], sorts = [] )=>{

    const sort = sorts.reduce((acc, val) => {
        acc[`order[${val.id}]`] = val.desc ? "DESC" : "ASC";
        return acc;
    }, {});

    const filter = filters.reduce((acc, val) => {
        acc[val.id] = val.value;
        return acc;
    }, {});

    return { ...sort , ...filter };

};
*/
export const tableStateToApiOptions=({pageIndex, pageSize, apiOptions, requestFilters={}})=>{

    const params = {...requestFilters};
    if( typeof pageIndex === 'number')
        params.page = pageIndex+1;//Server-side pagination starts with 1 instead of 0
    if( typeof pageSize === 'number')
        params.itemsPerPage = pageSize;

    return {
        ...apiOptions,
        pagination: true,
        params,
    };
};

export default useTideTable;
