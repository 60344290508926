import moment from 'moment';

/**
 * This copies 'property' from one object to another. Checking that everything exists or throwing "throwMessage"
 * if it doesn't exist
 *
 * @param fromObj
 * @param toObj
 * @param property
 * @param throwMessage
 * @param mapToProperty string Change the property name in the receiver to this
 */
export const copyPropertyOrThrow=(fromObj, toObj, property, throwMessage, mapToProperty)=>{
    if(!toObj || !property)
        throw new Error('Error copiando campos.');

    if(!fromObj || typeof fromObj[property]==='undefined' || fromObj[property]===null)
        throw new Error(throwMessage);

    toObj[mapToProperty||property]=fromObj[property];
};

/**
 * This copies 'property' from one object to another. Checking that everything exists or throwing "throwMessage"
 * if it doesn't exist
 *
 * @param fromObj
 * @param toObj
 * @param property
 * @param throwMessage
 * @param mapToProperty string Change the property name in the receiver to this
 */
export const copyAndFormatDateOrThrow=(fromObj, toObj, property, throwMessage, mapToProperty)=>{
    if(!toObj || !property)
        throw new Error('Error copiando campos.');

    if(!fromObj || !fromObj[property])
        throw new Error(throwMessage);

    const date = moment(fromObj[property]);
    if(!date.isValid())
        throw new Error(throwMessage);

    toObj[mapToProperty||property]=date.format('YYYY-MM-DD HH:mm');
};

/**
 * This copies 'property' from one object to another. Checking that everything exists or throwing "throwMessage"
 * if it doesn't exist
 *
 * @param fromObj
 * @param toObj
 * @param property
 * @param mapToProperty string Change the property name in the receiver to this
 */
export const copyBoolean=(fromObj, toObj, property, mapToProperty)=>{
    if(!toObj || !property)
        throw new Error('Error copiando campos.');

    if(!fromObj || typeof fromObj[property]==='undefined')
        toObj[mapToProperty||property]=false;

    toObj[mapToProperty||property]=!!fromObj[property];
};

export const validateStringLength = ( str, length, throwMessage, validation )=>{
    if(!str || typeof str !== 'string')
        throw  new Error(throwMessage);
    if(validation === 'equal' && str.length !== length)
        throw  new Error(throwMessage);
    if(validation === 'max' && str.length > length)
        throw  new Error(throwMessage);
    else if( str.length < length)//min
        throw  new Error(throwMessage);
    return true;
};

export const validateStringContainsOnlyNumbers = (str, throwMessage)=>{
    if(!str.match(/^[0-9]+$/) )
        throw new Error(throwMessage);
}

/* Validating the email format. */
export const validateEmail = (email, message) => {
    if(!String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ))
        throw new Error(message);
};

/**
 * This copies 'property' from one object to another. Checking that everything exists or setting null un toObj.property
 * if it doesn't exist
 *
 * @param fromObj
 * @param toObj
 * @param property
 * @param mapToProperty string Change the property name in the receiver to this
 */
export const copyPropertyOrNull=(fromObj, toObj, property, mapToProperty)=>{
    if(!toObj || !property)
        throw new Error('Error copiando campos.');

    if(!fromObj || typeof fromObj[property]==='undefined' || fromObj[property]===null)
        toObj[mapToProperty||property]=null;

    toObj[mapToProperty||property]=fromObj[property];
};

/**
 * This tries to copy
 * @param fromObj
 * @param toObj
 * @param property
 */
export const copyPropertyIdOrNull=(fromObj, toObj, property)=>{
    if(!toObj || !property)
        throw new Error('Error copiando campos.');

    if(!fromObj || !fromObj[property])
        toObj[property]=null;
    else if(typeof fromObj[property] === 'object')
        toObj[property]=fromObj[property].id;
    else
        toObj[property]=fromObj[property];
};

/**
 * This tries to copy
 * @param fromObj
 * @param toObj
 * @param property
 */
export const copyPropertyIdOrThrow=(fromObj, toObj, property, message, mapToProperty)=>{
    if(!mapToProperty)
        mapToProperty=property;

    if(!fromObj || !fromObj[property])
        throw new Error(message||'Error copiando campos.');

    else if(typeof fromObj[property] === 'object')
        toObj[mapToProperty]=fromObj[property].id;
    else
        toObj[mapToProperty]=fromObj[property];
};

export const copyPropertyValueOrThrow=(fromObj, toObj, property, message, mapTo)=>{
    if(!toObj || !property)
        throw new Error('Error copiando campos.');

    if(!mapTo)
        mapTo=property;

    if(!fromObj || !fromObj[property])
        throw new Error(message);
    else if(typeof fromObj[property] === 'object')
        toObj[mapTo]=fromObj[property].value;
    else
        toObj[mapTo]=fromObj[property];
};

export const copyPropertyValueOrNull=(fromObj, toObj, property)=>{
    if(!toObj || !property)
        throw new Error('Error copiando campos.');

    if(!fromObj || !fromObj[property])
        toObj[property]=null;
    else if(typeof fromObj[property] === 'object')
        toObj[property]=fromObj[property].value;
    else
        toObj[property]=fromObj[property];
};

export const cleanParameters = (obj) => {
    Object.keys(obj).forEach(key => {
        if (obj[key] === undefined) {
          delete obj[key];
        }
    });

    return obj;
}