import React from 'react'

import './LabelStatus.scss';

const LabelStatus = ({status, statusText}) => {
    const labelClass = `LabelStatus--${status}`;

    return (
        <>
            {statusText && 
                <div className={`LabelStatus ${labelClass}`}>
                    {statusText}
                </div>}
        </>
    );
}

export default LabelStatus;